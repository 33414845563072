/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: normal;
  vertical-align: baseline;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  /* This needs to be set or some font faced fonts look bold on Mac in Chrome/Webkit based browsers. */
  -moz-osx-font-smoothing: grayscale;
  /* Fixes font bold issue in Firefox version 25+ on Mac */
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, main, nav, section {
  display: block;
}

body, html {
  height: 100%;
  line-height: 1;
  letter-spacing: 1px;
}

body {
  background-color: #ffffff;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

img {
  vertical-align: top;
  width: 100%;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: '';
  content: none;
}

q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

*, *:before, *:after {
  box-sizing: border-box;
}

.clearfix {
  clear: both;
}

.bg-red {
  background-color: #e11c24;
}

.bg-white {
  background-color: #ffffff;
}

.bg-black {
  background-color: #231f20;
}

.bg-grey {
  background-color: #404040;
}

.font-white {
  color: #ffffff;
}

.font-red {
  color: #e11c24;
}

.font-black {
  color: #231f20;
}

.font-grey {
  color: #404040;
}

@font-face {
  font-family: "aileron";
  src: url("/fonts/AileronLight.eot?") format("eot"), url("/fonts/AileronLight.woff") format("woff"), url("/fonts/AileronLight.ttf") format("truetype"), url("/fonts/AileronLight.svg#aileron") format("svg");
}

@font-face {
  font-family: "aileronB";
  src: url("/fonts/AileronBold.eot?") format("eot"), url("/fonts/AileronBold.woff") format("woff"), url("/fonts/AileronBold.ttf") format("truetype"), url("/fonts/AileronBold.svg#aileronB") format("svg");
}

.text-uppercase {
  text-transform: uppercase;
}

.font-bold {
  font-family: aileronB;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: aileronB;
  line-height: 1.2em;
}

ul, ol, p {
  font-family: aileron;
  line-height: 1.3em;
}

.disclaimer {
  font-size: 14px;
}

.slick-dots {
  z-index: 9;
  bottom: 20px;
  height: 20px;
}

.slick-dots li {
  margin: 0 4px;
}

.slick-dots li button:before {
  content: "";
  font-size: 6px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: transparent;
  opacity: 1;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
}

.slick-slide {
  outline: none !important;
}

.slick-list {
  outline: none !important;
}

.slick-slide:focus {
  outline: none !important;
}

.slick-prev,
.slick-next {
  display: block;
  position: absolute;
  z-index: 2;
}

.slick-prev {
  left: -50px;
  z-index: 9;
  width: 40px;
  height: 40px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 40px;
  opacity: 0.5;
}

.slick-prev:before {
  content: '';
}

.slick-prev:hover, .slick-prev:focus, .slick-prev:active {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 40px;
  opacity: 1;
}

.slick-next {
  right: -50px;
  z-index: 9;
  width: 40px;
  height: 40px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 40px;
  opacity: 0.5;
}

.slick-next:before {
  content: '';
}

.slick-next:hover, .slick-next:focus, .slick-next:active {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 40px;
  opacity: 1;
}

.slick-dotted.slick-slider {
  margin-bottom: 0;
}

.product-img-slider .slick-prev {
  left: 10px;
}

.product-img-slider .slick-next {
  right: 10px;
}

.divider {
  height: 4px;
  width: 100px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.divider.align-center {
  margin: 20px auto 20px;
}

.divider.header {
  margin: 20px auto 20px;
}

@media all and (min-width: 1024px) {
  .divider.header {
    margin: 20px 0 20px;
  }
}

.feature-section {
  position: relative;
  max-width: 1200px;
  width: 90%;
  margin: -20px auto 50px;
  border-radius: 5px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
  padding: 35px 30px;
  background-color: #ffffff;
}

.feature-section p {
  text-align: center;
}

.feature-section .feature-text {
  max-width: 900px;
  margin: 0 auto;
}

.feature-section .quater-wrapper {
  text-align: center;
}

.feature-section .quater-wrapper .quater {
  width: 100%;
  display: inline-block;
  padding: 20px 10px;
}

.feature-section .quater-wrapper .quater .icon {
  width: 100px;
  margin: 0 auto 15px;
}

.feature-section .quater-wrapper .splitter {
  height: 1px;
  width: 200px;
  margin: 0 auto;
  display: inline-block;
  background-color: #231f20;
}

@media all and (min-width: 768px) {
  .feature-section .quater-wrapper .quater {
    width: 48%;
    vertical-align: middle;
  }
  .feature-section .quater-wrapper .splitter {
    height: 100px;
    width: 1px;
    vertical-align: middle;
  }
  .feature-section .quater-wrapper .splitter.hide {
    display: none;
  }
}

@media all and (min-width: 1024px) {
  .feature-section .quater-wrapper .quater {
    width: 24%;
    vertical-align: middle;
  }
  .feature-section .quater-wrapper .splitter {
    height: 100px;
    width: 1px;
    vertical-align: middle;
  }
  .feature-section .quater-wrapper .splitter.hide {
    display: inline-block;
  }
}

.cube-wrapper .cube {
  width: 100%;
  text-align: center;
  padding: 80px 20px;
}

.cube-wrapper .cube .cube-content {
  max-width: 400px;
  margin: 0 auto;
}

.cube-wrapper .cube .cube-content h2 {
  margin-bottom: 20px;
}

.cube-wrapper .cube .cube-content .icon {
  width: 100px;
  margin: 0 auto 15px;
}

.cube-wrapper .cube.mission {
  background-image: url("../images/about/mission.png");
  background-size: cover;
  background-position: center;
}

.cube-wrapper .cube.vision {
  background-image: url("../images/about/vision.png");
  background-size: cover;
  background-position: center;
}

.cube-wrapper .cube.warehouse {
  background-image: url("../images/whatwedo/warehouse.png");
  background-size: cover;
  background-position: center;
}

.cube-wrapper .cube.stockpile {
  background-image: url("../images/whatwedo/stockpile.png");
  background-size: cover;
  background-position: center;
}

.cube-wrapper .cube.cargo {
  background-image: url("../images/whatwedo/cargo.png");
  background-size: cover;
  background-position: center;
}

.cube-wrapper .cube.industrial {
  background-image: url("../images/whatwedo/industrial.png");
  background-size: cover;
  background-position: center;
}

@media all and (min-width: 1024px) {
  .cube-wrapper {
    display: flex;
  }
  .cube-wrapper.reverse {
    flex-direction: row-reverse;
  }
  .cube-wrapper .cube {
    padding: 100px 50px;
  }
}

.nav {
  background-color: #231f20;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 99;
  top: 0;
  width: 100%;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.9);
}

.nav .logo {
  width: 70px;
}

.nav .nav-toggle {
  margin-left: auto;
  cursor: pointer;
  width: 40px;
}

.nav .navigation-mobile {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: -110vh;
  left: 0;
  z-index: 99;
  background-color: #231f20;
  transition: all 1s ease-in-out;
}

.nav .navigation-mobile .close-menu {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  cursor: pointer;
}

.nav .navigation-mobile .mobile-nav-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.nav .navigation-mobile .mobile-nav-wrapper .nav-button {
  text-align: center;
  margin: 15px 15px;
  display: block;
}

.nav .navigation-mobile.active {
  top: 0;
}

.nav .navigation-desktop {
  display: none;
}

@media all and (min-width: 1024px) {
  .nav .nav-toggle {
    display: none;
  }
  .nav .navigation-mobile {
    display: none;
  }
  .nav .navigation-desktop {
    display: block;
    margin-left: auto;
  }
}

.nav-button {
  transition: all 0.3s ease-in-out;
  font-size: 14px;
  color: #ffffff;
  text-transform: uppercase;
  margin: 0 15px;
  font-family: aileronB;
  display: inline-block;
  cursor: pointer;
}

.nav-button:hover {
  color: #e11c24;
}

.nav-button:last-child {
  margin-right: 0;
}

.footer {
  background-color: #231f20;
  padding: 15px 20px;
  text-align: center;
}

.page-header {
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: 550px;
  background-repeat: no-repeat;
  background-size: cover;
}

.page-header#home {
  background-image: url("../images/header/home.png");
  background-position: center;
}

.page-header#about {
  background-image: url("../images/header/about.png");
  background-position: center;
}

.page-header#whyus {
  background-image: url("../images/header/whyus.png");
  background-position: center;
}

.page-header#whatwedo {
  background-image: url("../images/header/whatwedo.png");
  background-position: center;
}

.page-header#contact {
  background-image: url("../images/header/contact.png");
  background-position: center;
}

.page-header#quotation {
  background-image: url("../images/header/quotation.png");
  background-position: center;
}

.page-header .center-content {
  max-width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  transform: translate(-50%, -50%);
  padding: 40px 35px 50px;
  text-align: center;
  border: 5px solid #ffffff;
}

.page-header .center-content .csh-button {
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
}

.page-header .header-title {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  transform: translate(-50%, -50%);
  text-align: center;
}

@media all and (min-width: 768px) {
  .page-header .header-title {
    left: 50px;
    transform: translateY(-50%);
    text-align: left;
  }
}

.section-about {
  width: 90%;
  margin: 0 auto 40px;
  max-width: 1200px;
  text-align: center;
}

.section-about .half-section {
  width: 100%;
}

.section-about .half-section .text-wrapper {
  text-align: left;
  padding: 20px 0;
}

.section-about .half-section .text-wrapper .about-h-text {
  padding: 20px 0 30px;
}

@media all and (min-width: 1024px) {
  .section-about .half-section {
    width: 49%;
    display: inline-block;
    vertical-align: middle;
  }
  .section-about .half-section .text-wrapper {
    padding: 20px;
  }
}

.section-whyUs {
  width: 100%;
  padding: 40px 0;
  text-align: center;
  background: linear-gradient(rgba(0, 0, 0, 0.8) 100%, rgba(0, 0, 0, 0.8) 100%), url("../images/service-h.png");
  background-position: center;
  background-size: cover;
}

.section-whyUs .item-wrapper {
  width: 90%;
  max-width: 1200px;
  text-align: center;
  margin: 0 auto;
}

.section-whyUs .item-wrapper .item-list {
  padding: 25px 15px;
  max-width: 270px;
  width: 90%;
  margin: 10px auto;
  display: inline-block;
  background-color: #ffffff;
  border-radius: 5px;
  text-align: center;
}

.section-whyUs .item-wrapper .item-list img {
  width: 80px;
  margin-bottom: 20px;
}

.section-whyUs .item-wrapper .item-list h4 {
  margin-bottom: 20px;
}

@media all and (min-width: 768px) {
  .section-whyUs .item-wrapper .item-list {
    width: 45%;
    margin: 10px;
  }
}

@media all and (min-width: 1024px) {
  .section-whyUs .item-wrapper .item-list {
    width: 22%;
  }
}

.section-contact {
  width: 100%;
  padding: 40px 0;
}

.section-contact .contact-content {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}

.section-contact .contact-content .half-section {
  width: 100%;
}

.section-contact .contact-content .half-section.map {
  margin-bottom: 15px;
}

.section-contact .contact-content .half-section .company-title {
  margin-bottom: 15px;
}

.section-contact .contact-content .half-section .detail-list {
  margin-bottom: 20px;
}

.section-contact .contact-content .half-section .detail-list .icon {
  width: 10%;
  max-width: 30px;
  display: inline-block;
  vertical-align: middle;
}

.section-contact .contact-content .half-section .detail-list .text {
  width: 89%;
  display: inline-block;
  vertical-align: middle;
}

.section-contact .contact-content .half-section .detail-list:last-child .icon, .section-contact .contact-content .half-section .detail-list:last-child .text {
  vertical-align: top;
}

@media all and (min-width: 1024px) {
  .section-contact .contact-content .half-section {
    padding: 10px;
    display: inline-block;
    vertical-align: middle;
    width: 48%;
  }
  .section-contact .contact-content .half-section.map {
    margin-bottom: 0;
  }
}

.team-wrapper {
  text-align: center;
  padding: 40px 25px;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.team-wrapper .gallery-wrapper {
  width: 90%;
  margin: 0 auto 40px;
  max-width: 700px;
}

.team-wrapper .gallery-wrapper .gallery-img {
  width: 100%;
}

.team-wrapper .gallery-wrapper .gallery-img img {
  width: 100%;
}

.team-wrapper .about-wrapper {
  width: 100%;
}

.team-wrapper .about-wrapper .half-section {
  text-align: left;
  padding: 0 10px;
}

.team-wrapper .about-wrapper .half-section p {
  margin-bottom: 20px;
}

@media all and (min-width: 1024px) {
  .team-wrapper .about-wrapper {
    display: flex;
  }
}

.whyus-highlight-section {
  position: relative;
  max-width: 1200px;
  width: 90%;
  margin: 0 auto 40px;
}

.whyus-highlight-section .quater-wrapper {
  text-align: center;
}

.whyus-highlight-section .quater-wrapper .quater {
  vertical-align: top;
  width: 100%;
  display: inline-block;
  padding: 20px 15px;
}

.whyus-highlight-section .quater-wrapper .quater .icon {
  max-width: 150px;
  margin: 0 auto 20px;
}

.whyus-highlight-section .quater-wrapper .quater h3 {
  margin-bottom: 20px;
}

@media all and (min-width: 768px) {
  .whyus-highlight-section .quater-wrapper .quater {
    width: 48%;
    vertical-align: top;
  }
}

@media all and (min-width: 1180px) {
  .whyus-highlight-section .quater-wrapper .quater {
    width: 24%;
    vertical-align: top;
  }
}

.safety-policy {
  width: 100%;
  padding: 40px 25px;
}

.safety-policy .safety-content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.safety-policy .onethird-wrapper {
  width: 100%;
  padding: 20px 0;
}

.safety-policy .onethird-wrapper .onethird {
  padding: 10px 15px;
}

.safety-policy .onethird-wrapper .onethird p {
  margin-bottom: 10px;
}

.safety-policy .onethird-wrapper .onethird ul {
  text-align: left;
  list-style-type: lower-alpha;
}

@media all and (min-width: 960px) {
  .safety-policy .onethird {
    width: 32%;
    display: inline-block;
    vertical-align: top;
  }
}

.project-wrapper {
  padding: 40px 0;
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.project-wrapper .quater-wrapper {
  text-align: center;
}

.project-wrapper .quater-wrapper .quater {
  width: 100%;
  display: inline-block;
  padding: 20px 10px;
  vertical-align: top;
}

.project-wrapper .quater-wrapper .quater .img-wrapper {
  max-width: 500px;
  margin-bottom: 15px;
}

@media all and (min-width: 768px) {
  .project-wrapper .quater-wrapper .quater {
    width: 48%;
  }
}

@media all and (min-width: 1024px) {
  .project-wrapper .quater-wrapper .quater {
    width: 24%;
  }
}

.future-container .future-wrapper {
  padding: 40px 0;
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.future-container .future-wrapper h3 {
  margin-bottom: 15px;
}

.future-container .future-wrapper p {
  margin-bottom: 15px;
}

.map-section {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  padding: 40px 0;
}

.contact-section .profile {
  text-align: left;
}

.contact-section .profile .company-content {
  max-width: 500px;
  margin: 0 auto;
}

.contact-section .profile .company-content .logo {
  max-width: 200px;
  margin: 0 auto 30px;
}

.contact-section .profile .company-content .company-title {
  margin-bottom: 15px;
}

.contact-section .profile .company-content .detail-list {
  margin-bottom: 20px;
}

.contact-section .profile .company-content .detail-list .icon {
  width: 10%;
  max-width: 30px;
  display: inline-block;
  vertical-align: middle;
}

.contact-section .profile .company-content .detail-list .text {
  width: 89%;
  display: inline-block;
  vertical-align: middle;
}

.contact-section .profile .company-content .detail-list:last-child .icon,
.contact-section .profile .company-content .detail-list:last-child .text {
  vertical-align: top;
}

form {
  width: 100%;
  padding: 20px;
  max-width: 900px;
  text-align: center;
  margin: 0 auto;
}

form select,
form input,
form textarea {
  text-align: left;
  border: 0;
  font-family: aileron;
  font-size: 14px;
  -webkit-appearance: none;
  color: #231f20;
  outline: none;
  display: block;
  padding: 10px 15px;
  margin: 0;
  border-radius: none;
  width: 100%;
  vertical-align: top;
  background-color: #ffffff;
}

form select.crypsec-button,
form input.crypsec-button,
form textarea.crypsec-button {
  width: auto;
}

form select {
  border-radius: 0;
  padding-right: 30px;
  background-image: url("/images/icon/down.png");
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: center right 10px;
}

form label.error {
  font-family: aileron;
  font-weight: normal;
  text-align: left;
  margin: 5px 0 0 0;
  color: #ffffff;
  display: block;
  font-size: 14px;
  font-style: italic;
}

form label.error.red {
  color: #e11c24;
}

form ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #231f20;
}

form :-ms-input-placeholder {
  /* IE 10+ */
  color: #231f20;
}

form :-moz-placeholder {
  /* Firefox 18- */
  color: #231f20;
}

form textarea {
  resize: none;
  height: 100px;
}

@media all and (min-width: 768px) {
  form textarea {
    height: 150px;
  }
}

form .input-section {
  margin-bottom: 15px;
}

.onethird-wrapper .onethird-section {
  width: 100%;
}

@media all and (min-width: 1024px) {
  .onethird-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .onethird-wrapper .onethird-section {
    width: 32%;
    display: inline-block;
  }
}

.half-wrapper .half-section {
  width: 100%;
}

@media all and (min-width: 1024px) {
  .half-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .half-wrapper .half-section {
    width: 48%;
  }
}

.quotation-wrapper {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  padding: 40px 0;
}

.quotation-wrapper .input-border {
  border: 1px solid #231f20;
}

.popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.8);
  display: none;
}

.popup-wrapper.active {
  display: block;
}

.popup-wrapper .close-popup {
  position: absolute;
  top: 20px;
  width: 30px;
  right: 20px;
  cursor: pointer;
  z-index: 100;
}

.popup-wrapper .popup-content {
  width: 100%;
  text-align: center;
  padding: 20px;
  max-width: 900px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.popup-wrapper .popup-content p {
  margin: 20px;
}

@media all and (max-height: 500px) {
  .popup-wrapper .popup-content {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
  }
}

.popup-wrapper .popup-content#loadingMSG, .popup-wrapper .popup-content#successMSG, .popup-wrapper .popup-content#failMSG {
  display: none;
}

.popup-wrapper .popup-content#loadingMSG.active, .popup-wrapper .popup-content#successMSG.active, .popup-wrapper .popup-content#failMSG.active {
  display: block;
}

.loading-wrapper {
  width: 200px;
  margin: 0 auto;
}

.ball {
  width: 40px;
  height: 6px;
  margin: 6px auto;
  border-radius: 3px;
}

.ball:nth-child(1) {
  background: #ffffff;
  -webkit-animation: right 1s infinite ease-in-out;
  -moz-animation: right 1s infinite ease-in-out;
  animation: right 1s infinite ease-in-out;
}

.ball:nth-child(2) {
  background: #e11c24;
  -webkit-animation: left 1.1s infinite ease-in-out;
  -moz-animation: left 1.1s infinite ease-in-out;
  animation: left 1.1s infinite ease-in-out;
}

.ball:nth-child(3) {
  background: #404040;
  -webkit-animation: right 1.05s infinite ease-in-out;
  -moz-animation: right 1.05s infinite ease-in-out;
  animation: right 1.05s infinite ease-in-out;
}

.ball:nth-child(4) {
  background: #e11c24;
  -webkit-animation: left 1.15s infinite ease-in-out;
  -moz-animation: left 1.15s infinite ease-in-out;
  animation: left 1.15s infinite ease-in-out;
}

@-webkit-keyframes right {
  0% {
    -webkit-transform: translate(-15px);
  }
  50% {
    -webkit-transform: translate(15px);
  }
  100% {
    -webkit-transform: translate(-15px);
  }
}

@-webkit-keyframes left {
  0% {
    -webkit-transform: translate(15px);
  }
  50% {
    -webkit-transform: translate(-15px);
  }
  100% {
    -webkit-transform: translate(15px);
  }
}

@-moz-keyframes right {
  0% {
    -moz-transform: translate(-15px);
  }
  50% {
    -moz-transform: translate(15px);
  }
  100% {
    -moz-transform: translate(-15px);
  }
}

@-moz-keyframes left {
  0% {
    -moz-transform: translate(15px);
  }
  50% {
    -moz-transform: translate(-15px);
  }
  100% {
    -moz-transform: translate(15px);
  }
}

@keyframes right {
  0% {
    transform: translate(-15px);
  }
  50% {
    transform: translate(15px);
  }
  100% {
    transform: translate(-15px);
  }
}

@keyframes left {
  0% {
    transform: translate(15px);
  }
  50% {
    transform: translate(-15px);
  }
  100% {
    transform: translate(15px);
  }
}

.csh-button {
  width: 200px;
  text-align: center;
  transition: all 0.27s ease-in-out;
  cursor: pointer;
  padding: 0;
}

.csh-button p {
  line-height: 50px;
  font-family: aileronB;
  text-transform: uppercase;
}

.csh-button.red {
  background-color: #e11c24;
  color: #ffffff;
  font-family: aileronB;
  text-transform: uppercase;
  line-height: 50px;
}

.csh-button.red p {
  color: #ffffff;
}

.csh-button.red:hover {
  background-color: #ffffff;
}

.csh-button.red:hover p {
  color: #e11c24;
}

.csh-button.red-black {
  background-color: #e11c24;
  color: #ffffff;
  font-family: aileronB;
  text-transform: uppercase;
  line-height: 50px;
}

.csh-button.red-black p {
  color: #ffffff;
}

.csh-button.red-black:hover {
  background-color: #231f20;
}

.csh-button.red-black:hover p {
  color: #ffffff;
}

.csh-button.aligncenter {
  margin: 0 auto;
}

.csh-button.black {
  background-color: #231f20;
  color: #ffffff;
  font-family: aileronB;
  text-transform: uppercase;
  line-height: 50px;
}

.csh-button.black p {
  color: #ffffff;
}

.csh-button.black:hover {
  background-color: #ffffff;
  color: #e11c24;
}

.csh-button.black:hover p {
  color: #e11c24;
}

.csh-button.white {
  background-color: #ffffff;
}

.csh-button.white p {
  color: #231f20;
}

.csh-button.white:hover {
  background-color: #e11c24;
}

.csh-button.white:hover p {
  color: #ffffff;
}

.csh-link {
  font-family: aileronB;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.27s ease-in-out;
}

.csh-link:hover {
  text-decoration: underline;
}
