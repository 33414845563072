.loading-wrapper {
  width: 200px;
  margin: 0 auto;
}

.ball {
  width: 40px;
  height: 6px;
  margin: 6px auto;
  border-radius: 3px;
  }    
    .ball:nth-child(1) {
      background: $white;
      -webkit-animation: right 1s infinite ease-in-out;
      -moz-animation: right 1s infinite ease-in-out;
      animation: right 1s infinite ease-in-out;    
    }

    .ball:nth-child(2) {
      background: $red;
      -webkit-animation: left 1.1s infinite ease-in-out;
      -moz-animation: left 1.1s infinite ease-in-out;
      animation: left 1.1s infinite ease-in-out;
    }

    .ball:nth-child(3) {
      background: $grey;
      -webkit-animation: right 1.05s infinite ease-in-out;
      -moz-animation: right 1.05s infinite ease-in-out;
      animation: right 1.05s infinite ease-in-out;
    }

    .ball:nth-child(4) {
      background: $red;
      -webkit-animation: left 1.15s infinite ease-in-out;
      -moz-animation: left 1.15s infinite ease-in-out;
      animation: left 1.15s infinite ease-in-out;
     }


@-webkit-keyframes right {
  0%   { -webkit-transform: translate(-15px);   }
  50%  { -webkit-transform: translate(15px);    }
  100% { -webkit-transform: translate(-15px);   }
}

@-webkit-keyframes left {
  0%   { -webkit-transform: translate(15px);    }
  50%  { -webkit-transform: translate(-15px);   }
  100% { -webkit-transform: translate(15px);    }
}

@-moz-keyframes right {
  0%   { -moz-transform: translate(-15px);   }
  50%  { -moz-transform: translate(15px);    }
  100% { -moz-transform: translate(-15px);   }
}

@-moz-keyframes left {
  0%   { -moz-transform: translate(15px);    }
  50%  { -moz-transform: translate(-15px);   }
  100% { -moz-transform: translate(15px);    }
}

@keyframes right {
  0%   { transform: translate(-15px);  }
  50%  { transform: translate(15px);   }
  100% { transform: translate(-15px);  }
}

@keyframes left {
  0%   { transform: translate(15px);   }
  50%  { transform: translate(-15px);  }
  100% { transform: translate(15px);   }
}
