.popup-wrapper{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 999999;
	background-color: rgba(0,0,0,0.8);
	display: none;
	&.active{
		display: block;
	}
	.close-popup{
		position: absolute;
		top:20px;
		width: 30px;
		right: 20px;
		cursor: pointer;
		z-index: 100;
	}
	.popup-content{
		width: 100%;
		text-align: center;
		padding: 20px;
		max-width: 900px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		p{
			margin: 20px;
		}
		@media all and (max-height: 500px){
			width: 100%;
			height: 100vh;
			overflow-y: scroll;
		}
		&#loadingMSG,&#successMSG,&#failMSG{
			display: none;
			&.active{
				display: block;
			}
		}

	}
}