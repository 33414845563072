.text-uppercase {
  text-transform: uppercase;
}

.font-bold {
  font-family: aileronB;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: aileronB;
  line-height: 1.2em;
}

ul,ol,p {
  font-family: aileron;
  line-height: 1.3em;
}

.disclaimer{
	font-size: 14px;
}
