.section-about {
  width: 90%;
  margin: 0 auto 40px;
  max-width: 1200px;
  text-align: center;
  .half-section {
    width: 100%;
    .text-wrapper {
      text-align: left;
      padding: 20px 0;
      .about-h-text {
        padding: 20px 0 30px;
      }
    }
  }
  @media all and (min-width: 1024px) {
    .half-section {
      width: 49%;
      display: inline-block;
      vertical-align: middle;
      .text-wrapper {
        padding: 20px;
      }
    }
  }
}

.section-whyUs {
  width: 100%;
  padding: 40px 0;
  text-align: center;
  background: linear-gradient( rgba(0, 0, 0, 0.8) 100%, rgba(0, 0, 0, 0.8)100%), url('../images/service-h.png');
  background-position: center;
  background-size: cover;
  .item-wrapper {
    width: 90%;
    max-width: 1200px;
    text-align: center;
    margin: 0 auto;
    .item-list {
      padding: 25px 15px;
      max-width: 270px;
      width: 90%;
      margin: 10px auto;
      display: inline-block;
      background-color: $white;
      border-radius: 5px;
      text-align: center;
      img {
        width: 80px;
        margin-bottom: 20px;
      }
      h4 {
        margin-bottom: 20px;
      }
    }
    @media all and (min-width: 768px) {
      .item-list {
        width: 45%;
        margin: 10px;
      }
    }
    @media all and (min-width: 1024px) {
      .item-list {
        width: 22%;
      }
    }
  }
}

.section-contact {
  width: 100%;
  padding: 40px 0;
  .contact-content {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    .half-section {
      width: 100%;
      &.map{
      	margin-bottom: 15px;
      }
      .company-title {
        margin-bottom: 15px;
      }
      .detail-list {
        margin-bottom: 20px;
        .icon {
          width: 10%;
          max-width: 30px;
          display: inline-block;
          vertical-align: middle;
        }
        .text {
          width: 89%;
          display: inline-block;
          vertical-align: middle;
        }
        &:last-child{
        	.icon,.text{
        		vertical-align: top;
        	}
        }
      }
    }
    @media all and (min-width: 1024px) {
      .half-section {
      	&.map{
      		margin-bottom: 0;
      	}
        padding: 10px;
        display: inline-block;
        vertical-align: middle;
        width: 48%;
      }
    }
  }
}
