.whyus-highlight-section {
  position: relative;
  max-width: 1200px;
  width: 90%;
  margin: 0 auto 40px;
  .quater-wrapper {
    text-align: center;
    .quater {
      .icon {
        max-width: 150px;
        margin: 0 auto 20px;
      }
      h3{
        margin-bottom: 20px;
      }
      vertical-align: top;
      width: 100%;
      display: inline-block;
      padding: 20px 15px;
    }
    @media all and (min-width: 768px) {
      .quater {
        width: 48%;
        vertical-align: top;
      }
    }
    @media all and (min-width: 1180px) {
      .quater {
        width: 24%;
        vertical-align: top;
      }
    }
  }
}

.safety-policy{
  width: 100%;
  padding: 40px 25px;
  .safety-content{
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
  }
  .onethird-wrapper{
    width: 100%;
    padding: 20px 0;
    .onethird{
      padding: 10px 15px;
      p{
        margin-bottom: 10px;
      }
      ul{
        text-align: left;
        list-style-type: lower-alpha;
      }
    }
  }
  @media all and (min-width: 960px){
    .onethird{
      width: 32%;
      display: inline-block;
      vertical-align: top;
    }
  }
}
