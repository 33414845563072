.team-wrapper {
  text-align: center;
  padding: 40px 25px;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  .gallery-wrapper {
    width: 90%;
    margin: 0 auto 40px;
    max-width: 700px;
    .gallery-img {
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
  .about-wrapper {
    width: 100%;
    .half-section {
    	text-align: left;
    	padding:0 10px;
    	p{
    		margin-bottom:20px;
    	}
    }
    @media all and (min-width: 1024px) {
    	display: flex;
    }
  }
}
