.slick-dots {
  z-index: 9;
  //box-sizing: border-box;
  bottom: 20px;
  height: 20px;
}

.slick-dots li {
  margin: 0 4px;
}

.slick-dots li button:before {
  content: "";
  font-size: 6px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: transparent;
  //border: 2px solid $white;
  opacity: 1;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
  //background-color: $white;
}

.slick-slide {
  outline: none !important;
}

.slick-list {
  outline: none !important;
}

.slick-slide:focus {
  outline: none !important;
}

.slick-prev,
.slick-next {
  display: block;
  position: absolute;
  z-index: 2;
}



.slick-prev {
  left: -50px;
  z-index: 9;
  width: 40px;
  height: 40px;
  //background-image: url('/images/icon/prev.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 40px;
  opacity: 0.5;
  &:before {
    content: '';
  }
  &:hover,
  &:focus,
  &:active {
    //background-image: url('/images/icon/prev.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 40px;
    opacity: 1;
  }
}

.slick-next {
  right: -50px;
  z-index: 9;
  width: 40px;
  height: 40px;
  //background-image: url('/images/icon/next.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 40px;
  opacity: 0.5;
  &:before {
    content: '';
  }

  &:hover,
  &:focus,
  &:active {
    //background-image: url('/images/icon/next.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 40px;
    opacity: 1;
  }
}

.slick-dotted.slick-slider {
  margin-bottom: 0;
}

.product-img-slider {
  .slick-prev {
    left: 10px;
  }

  .slick-next {
    right: 10px;
  }
}

