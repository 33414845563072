.divider {
  height: 4px;
  width: 100px;
  margin-top: 20px;
  margin-bottom: 20px;
  &.align-center{
    margin: 20px auto 20px;
  }
  &.header{
    margin: 20px auto 20px;
    @media all and (min-width: 1024px){
      margin: 20px 0 20px ;
    }
  }
}

.feature-section {
  position: relative;
  max-width: 1200px;
  width: 90%;
  margin: -20px auto 50px;
  border-radius: 5px;
  box-shadow: 0px 5px 10px $alpha-black;
  padding: 35px 30px;
  background-color: $white;
  p {
    text-align: center;
  }
  .feature-text{
    max-width: 900px;
    margin: 0 auto;
  }
  .quater-wrapper {
    text-align: center;
    .quater {
      width: 100%;
      display: inline-block;
      padding: 20px 10px;

      .icon {
        width: 100px;
        margin: 0 auto 15px;
      }
    }
    .splitter {
      height: 1px;
      width: 200px;
      margin: 0 auto;
      display: inline-block;
      background-color: $black;
    }
    @media all and (min-width: 768px) {
      .quater {
        width: 48%;
        vertical-align: middle;
      }
      .splitter {
        height: 100px;
        width: 1px;
        vertical-align: middle;
        &.hide{
          display: none;
        }
      }
    }
    @media all and (min-width: 1024px) {
      .quater {
        width: 24%;
        vertical-align: middle;
      }
      .splitter {
        height: 100px;
        width: 1px;
        vertical-align: middle;
        &.hide{
          display: inline-block;
        }
      }
    }
  }
}

.cube-wrapper {
  .cube {
    width: 100%;
    text-align: center;
    padding: 80px 20px;

    .cube-content {
      max-width: 400px;
      margin: 0 auto;
      h2 {
        margin-bottom: 20px;
      }
      .icon{
        width: 100px;
        margin: 0 auto 15px;
      }
    }
    &.mission {
      background-image: url("../images/about/mission.png");
      background-size: cover;
      background-position: center;
    }
    &.vision {
      background-image: url("../images/about/vision.png");
      background-size: cover;
      background-position: center;
    }
    &.warehouse {
      background-image: url("../images/whatwedo/warehouse.png");
      background-size: cover;
      background-position: center;
    }
    &.stockpile {
      background-image: url("../images/whatwedo/stockpile.png");
      background-size: cover;
      background-position: center;
    }
    &.cargo {
      background-image: url("../images/whatwedo/cargo.png");
      background-size: cover;
      background-position: center;
    }
    &.industrial {
      background-image: url("../images/whatwedo/industrial.png");
      background-size: cover;
      background-position: center;
    }
  }
  @media all and (min-width: 1024px) {
    display: flex;
    &.reverse{
      flex-direction: row-reverse; 
    }
    .cube{
      padding: 100px 50px;
    }
  }
}
