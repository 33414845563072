.csh-button {
  width: 200px;
  text-align: center;
  transition: all 0.27s ease-in-out;
  cursor: pointer;
  padding: 0;
  p {
    line-height: 50px;
    font-family: aileronB;
    text-transform: uppercase;
  }
  &.red {
    background-color: $red;
    color: $white;
    font-family: aileronB;
    text-transform: uppercase;
    line-height: 50px;
    p {
      color: $white;
    }
    &:hover {
      background-color: $white;
      p {
        color: $red;
      }
    }
  }
  &.red-black {
    background-color: $red;
    color: $white;
    font-family: aileronB;
    text-transform: uppercase;
    line-height: 50px;
    p {
      color: $white;
    }
    &:hover {
      background-color: $black;
      p {
        color: $white;
      }
    }
  }
  &.aligncenter {
    margin: 0 auto;
  }
  &.black {
    background-color: $black;
    color: $white;
    font-family: aileronB;
    text-transform: uppercase;
    line-height: 50px;
    p {
      color: $white;
    }
    &:hover {
      background-color: $white;
      color: $red;
      p {
        color: $red;
      }
    }
  }
  &.white {
    background-color: $white;
    p {
      color: $black;
    }
    &:hover {
      background-color: $red;
      p {
        color: $white;
      }
    }
  }
}

.csh-link {
  font-family: aileronB;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.27s ease-in-out;
  &:hover {
    text-decoration: underline;
  }
}
