.project-wrapper {
  padding: 40px 0;
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  .quater-wrapper {
    text-align: center;
    .quater {
      width: 100%;
      display: inline-block;
      padding: 20px 10px;
      vertical-align: top;
      .img-wrapper {
        max-width: 500px;
        margin-bottom: 15px;
      }
    }
    @media all and (min-width: 768px) {
      .quater {
        width: 48%;
      }
    }
    @media all and (min-width: 1024px) {
      .quater {
        width: 24%;
      }
    }
  }
}

.future-container {
  .future-wrapper {
    padding: 40px 0;
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    h3{
    	margin-bottom: 15px;
    }
    p{
    	margin-bottom: 15px;
    }
  }
}
