.map-section {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  padding: 40px 0;
  .map-wrapper {}
}

.contact-section {
  .profile {
    text-align: left;
    .company-content {
    	max-width: 500px;
    	margin: 0 auto;
      .logo {
        max-width: 200px;
        margin: 0 auto 30px;
      }
      .company-title {
        margin-bottom: 15px;
      }
      .detail-list {
        margin-bottom: 20px;
        .icon {
          width: 10%;
          max-width: 30px;
          display: inline-block;
          vertical-align: middle;
        }
        .text {
          width: 89%;
          display: inline-block;
          vertical-align: middle;
        }
        &:last-child {
          .icon,
          .text {
            vertical-align: top;
          }
        }
      }
    }
  }
}
