.nav {
  background-color: $black;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 99;
  top: 0;
  width: 100%;
  box-shadow: 0px 3px 8px rgba(0,0,0,0.9);
  .logo {
    width: 70px;
  }
  .nav-toggle {
    margin-left: auto;
    cursor: pointer;
    width: 40px;
  }
  .navigation-mobile {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: -110vh;
    left: 0;
    z-index: 99;
    background-color: $black;
    transition: all 1s ease-in-out;
    .close-menu {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 40px;
      cursor: pointer;
    }
    .mobile-nav-wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .nav-button {
        text-align: center;
        margin: 15px 15px;
        display: block;
      }
    }
    &.active {
      top: 0;
    }
  }
  .navigation-desktop {
    display: none;
  }
  @media all and (min-width: 1024px) {
    .nav-toggle {
      display: none;
    }
    .navigation-mobile {
      display: none;
    }
    .navigation-desktop {
      display: block;
      margin-left: auto;
    }
  }
}

.nav-button{
  transition: all 0.3s ease-in-out;
  font-size: 14px;
  color: $white;
  text-transform: uppercase;
  margin: 0 15px;
  font-family: aileronB;
  display: inline-block;
  cursor: pointer;
  &:hover{
    color:$red;
  }
  &:last-child{
    margin-right: 0;
  }
}
