form {
  width: 100%;
  padding: 20px;
  max-width: 900px;
  text-align: center;
  margin: 0 auto;
  select,
  input,
  textarea {
    text-align: left;
    border: 0;
    font-family: aileron;
    font-size: 14px;
    -webkit-appearance: none;
    color: $black;
    outline: none;
    display: block;
    padding: 10px 15px;
    margin: 0;
    border-radius: none;
    width: 100%;
    vertical-align: top;
    background-color: $white;
    &.crypsec-button {
      width: auto;
    }
  }
  select {
    border-radius: 0;
    padding-right: 30px;
    background-image: url('/images/icon/down.png');
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: center right 10px;
  }
  label.error {
    font-family: aileron;
    font-weight: normal;
    text-align: left;
    margin: 5px 0 0 0;
    color: $white;
    display: block;
    font-size: 14px;
    font-style: italic;
    &.red{
      color:$red;
    }
  }

  option[default] {}

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $black;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: $black;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: $black;
  }

  textarea {
    resize: none;
    height: 100px;
    @media all and (min-width: 768px){
      height: 150px;
    }
  }

  .input-section {
    margin-bottom: 15px;
  }
}

.onethird-wrapper{
  .onethird-section{
    width: 100%;
  }
  @media all and (min-width: 1024px){
    display: flex;
    justify-content: space-between; 
    .onethird-section{
      width: 32%;
      display: inline-block;
    }
  }
}

.half-wrapper{
  .half-section{
    width: 100%;
  }
  @media all and (min-width: 1024px){
    display: flex;
    justify-content: space-between; 
    .half-section{
      width: 48%;
    }
  }
}