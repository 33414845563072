$white :#ffffff;
$red :#e11c24;
$black:#231f20;
$grey:#404040;
$alpha-black: rgba(0,0,0,0.5);

.bg-red{
	background-color: $red;
}

.bg-white{
	background-color: $white;
}

.bg-black{
	background-color: $black;
}

.bg-grey{
	background-color: $grey;
}

.font-white{
	color:$white;
}

.font-red{
	color:$red;
}

.font-black{
	color:$black;
}

.font-grey{
	color:$grey;
}
