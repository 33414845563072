.page-header {
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: 550px;
  background-repeat: no-repeat;
  background-size: cover;
  &#home {
    background-image: url('../images/header/home.png');
    background-position: center;
  }

  &#about {
    background-image: url('../images/header/about.png');
    background-position: center;
  }

  &#whyus {
    background-image: url('../images/header/whyus.png');
    background-position: center;
  }

  &#whatwedo {
    background-image: url('../images/header/whatwedo.png');
    background-position: center;
  }

  &#contact {
    background-image: url('../images/header/contact.png');
    background-position: center;
  }

  &#quotation {
    background-image: url('../images/header/quotation.png');
    background-position: center;
  }

  .center-content {
    max-width: 500px;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    transform: translate(-50%, -50%);
    padding: 40px 35px 50px;
    text-align: center;
    border: 5px solid $white;
    .csh-button {
      position: absolute;
      bottom: -25px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .header-title {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    transform: translate(-50%, -50%);
    text-align: center;
    @media all and (min-width: 768px){
      left: 50px;
      transform: translateY(-50%);
      text-align: left;
    }
  }
}
